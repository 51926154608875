import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { TableSortLabel } from '@mui/material';
import { FormControl} from '@mui/material';
import { Slider, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { SeasonDropdown, LeagueDropdown, TeamDropdown, PositionDropdown, RosterTypeDropdown } from './Dropdowns';
import LoadingAnimation from './LoadingAnimation';
import './ImpactMetricsTable.css';
import PageTransition from './PageTransition';
import { seasons, rosterTypeMapping, metricsGPM, positionOrder } from './constants';
import { sortData, getComparator } from './SortingLogic';
import ColoredCell, { calculatePercentiles} from './ColoringLogic';

const baseUrl = process.env.REACT_APP_API_URL;

const GPMTable = () => {
    const [seasonId, setSeasonId] = useState('202425'); // Default to the current season or the first season in the list
    const [leagueId, setLeagueId] = useState('1'); // Default to B1 league

    // State variables
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [percentiles, setPercentiles] = useState({});
    const [orderDirection, setOrderDirection] = useState('desc');
    const [orderBy, setOrderBy] = useState('gpm');
    const [teamFilter, setTeamFilter] = useState([]);
    const [positionFilter, setPositionFilter] = useState([]);
    const [playerClassFilter, setPlayerClassFilter] = useState([]);
    const [minFilter, setMinFilter] = useState(100);
    const [appliedMinFilter, setAppliedMinFilter] = useState(100);
    const [maxMinutes, setMaxMinutes] = useState(2000);
    const [showJapanese, setShowJapanese] = useState(true);
    const [uniqueTeamsJP, setUniqueTeamsJP] = useState([]);
    const [uniqueTeamsEN, setUniqueTeamsEN] = useState([]);
    const [teamNameMapping, setTeamNameMapping] = useState({});
    const [isLoading, setIsLoading] = useState(false); // Add a loading state

    // Responsive styles
    const responsiveStyle = window.innerWidth <= 600 ? { fontSize: '11px' } : {};
    const responsiveCellStyle = window.innerWidth <= 600 ? { padding: '2px', minWidth: '30px' } : { padding: '2px', minWidth: '80px' };
    const responsiveRowStyle = window.innerWidth <= 600 ? { padding: '0px', minHeight: '10px' } : { padding: '0px', minHeight: '10px' };

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${baseUrl}/api/gpm-v2/?season_id=${seasonId}&league_id=${leagueId}`)
            .then(response => {
                setData(response.data);
                setMaxMinutes(Math.max(...response.data.map(player => player.min_played)));
                setPercentiles(calculatePercentiles(response.data, metricsGPM));
                const teamNameMapping = createTeamNameMapping(response.data);
                setTeamNameMapping(teamNameMapping);
                setUniqueTeamsJP(getUniqueTeams(response.data, 'team_names', teamNameMapping));
                setUniqueTeamsEN(getUniqueTeams(response.data, 'team_names_eng', teamNameMapping));
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Failed to fetch data", error);
                setError("Failed to fetch data");
                setIsLoading(false);
            });
    }, [seasonId, leagueId]);

    // Get unique team names (either Japanese or English) sorted by team ID
    const getUniqueTeams = (data, teamProp, teamNameMapping) => {
        const uniqueTeams = [...new Set(data.flatMap(player => player[teamProp]))].sort((a, b) => {
            return teamNameMapping[a]?.team_id - teamNameMapping[b]?.team_id;
        });
        return uniqueTeams;
    };

    // Create a mapping of team names to team IDs and English names
    const createTeamNameMapping = (data) => {
        return data.reduce((acc, player) => {
            player.team_names.forEach((teamName, index) => {
                acc[teamName] = {
                    team_id: player.team_ids[index],
                    team_name_eng: player.team_names_eng[index]
                };
            });
            return acc;
        }, {});
    };

    // Toggle between Japanese and English language
    const handleLanguageToggle = () => {
        setShowJapanese(!showJapanese);
        if (teamFilter.length) {
            setTeamFilter(showJapanese
                ? teamFilter.map(team => teamNameMapping[team]?.team_name_eng || team)
                : teamFilter.map(team => Object.keys(teamNameMapping).find(key => teamNameMapping[key].team_name_eng === team) || team)
            );
        }
    };

    const commonCellStyle = {
        transition: 'all 0.2s ease',
    };

    // Handle sorting request
    const handleSortRequest = (columnId) => {
        const isAsc = orderBy === columnId && orderDirection === 'desc';
        setOrderDirection(isAsc ? 'asc' : 'desc');
        setOrderBy(columnId);
    };

    // Filter data based on current filters
    const filteredData = data.filter((player) => {
        const playerTeams = showJapanese ? player.team_names : player.team_names_eng;
        const playerPositions = player.position.split('/');

        return (
            (!teamFilter || teamFilter.length === 0 || teamFilter.some(team => playerTeams.includes(team))) &&
            (!positionFilter || positionFilter.length === 0 || positionFilter.some(position => playerPositions.includes(position))) &&
            (!playerClassFilter || playerClassFilter.length === 0 || playerClassFilter.includes(player.player_class)) &&
            (parseInt(player.min_played, 10) >= appliedMinFilter)
        );
    });

    // Sort filtered data
    const sortedAndFilteredData = sortData(filteredData, getComparator(orderDirection, orderBy));

    // Position order
    const uniquePositions = [...new Set(data.flatMap((player) => player.position.split('/')))];
    const orderedPositions = positionOrder.filter(position => uniquePositions.includes(position));

    // Handle slider change committed
    const handleSliderChangeCommitted = (event, newValue) => {
        setAppliedMinFilter(newValue);
    };

    return (
        <PageTransition>
            <Container maxWidth="lg" sx={{ my: 10 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h4" gutterBottom className="impact-metrics-title">
                    B.LEAGUE <span className="impact-metrics-break">Guesstimated Plus-Minus</span>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ mr: 1 }}>
                        <a href="/introducing-gpm/" className="language-link" style={{ textDecoration: 'none' }}>GPMについて(日本語)</a>
                    </Typography>
                    <Typography variant="body1" sx={{ mr: 1 }}>
                        |
                    </Typography>
                    <Typography variant="body1">
                        <a href="/introducing-gpm/en/" className="language-link" style={{ textDecoration: 'none' }}>About GPM (English)</a>
                    </Typography>
                </Box>
                <Box 
                    sx={{ 
                        display: "flex", 
                        flexWrap: { xs: 'wrap', sm: 'nowrap' }, // Wrap on extra-small screens, no wrap on small screens and up
                        alignItems: "center", 
                        overflowX: 'auto' // Horizontal scroll on overflow
                    }}
                >
                    <SeasonDropdown
                        currentSeasonId={seasonId}
                        setSeasonId={setSeasonId}
                        seasons={seasons} // Pass the seasons array as a prop
                        className="impact-metrics-formControl"
                    />
                    <LeagueDropdown
                        selectedLeague={leagueId}
                        setLeagueId={setLeagueId}
                        className="impact-metrics-formControl"
                    />
                    <TeamDropdown
                        teamFilter={teamFilter}
                        setTeamFilter={setTeamFilter}
                        uniqueTeams={showJapanese ? uniqueTeamsJP : uniqueTeamsEN}
                        showJapanese={showJapanese}
                        className="impact-metrics-formControl"
                    />
                    <PositionDropdown
                        positionFilter={positionFilter}
                        setPositionFilter={setPositionFilter}
                        orderedPositions={orderedPositions}
                        className="impact-metrics-formControl"
                    />
                    <RosterTypeDropdown
                        playerClassFilter={playerClassFilter}
                        setPlayerClassFilter={setPlayerClassFilter}
                        rosterTypeMapping={rosterTypeMapping}
                        showJapanese={showJapanese}
                        className="impact-metrics-formControl"
                    />
                    <FormControl className="impact-metrics-slider" variant="outlined" sx={{ m: 1, minWidth: 200, height: 56, display: 'flex', flexDirection: 'row', alignItems: 'center', border: '1px solid #c4c4c4', borderRadius: '4px', p: 1 }}>
                        <Typography sx={{ mr: 2 }} variant="body2">MIN</Typography>
                        <Slider
                            value={minFilter}
                            onChange={(event, newValue) => setMinFilter(newValue)}
                            onChangeCommitted={handleSliderChangeCommitted}
                            aria-labelledby="min-slider"
                            valueLabelDisplay="auto"
                            min={0}
                            max={parseInt(maxMinutes, 10)}
                            sx={{ flexGrow: 1 }}
                        />
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: { xs: 'wrap', sm: 'nowrap' },
                        alignItems: "center",
                        overflowX: 'auto'
                    }}
                >
                    <Typography variant="body2" sx={{ mr: 1 }}>
                        English
                    </Typography>
                    <Switch
                        checked={!showJapanese}
                        onChange={handleLanguageToggle} // Updated handler
                        name="languageToggle"
                        inputProps={{ 'aria-label': 'language toggle' }}
                    />
                </Box>
                <div style={{ overflowX: 'auto' }}>
                    <TableContainer>
                        {error && <p>{error}</p>}
                        <AnimatePresence mode="wait">
                            {isLoading ? (
                                <motion.div
                                    key="loading"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                        <LoadingAnimation />
                                    </Box>
                                </motion.div>
                            ) : (
                                <motion.div
                                    key="table"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Table className="table-auto w-full" aria-label="Impact Metrics Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', ...responsiveStyle, ...responsiveCellStyle }}>
                                                    <TableSortLabel
                                                        active={false}
                                                        direction={'asc'}
                                                    >
                                                        Rank
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell 
                                                    component="th" 
                                                    className="sticky left-0 z-10 bg-white responsive-table-header" 
                                                >
                                                    <Typography
                                                        sx={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            minWidth: '120px', // Ensure you use string with px for inline styles in MUI sx prop
                                                            position: 'sticky',
                                                            left: 0,
                                                            zIndex: 10, // Ensure zIndex is high enough to be on top of other elements
                                                        }}
                                                        style={{ ...responsiveStyle, ...responsiveCellStyle }}
                                                    >
                                                        Player
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'age'}
                                                        direction={orderBy === 'age' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('age')}
                                                    >
                                                        Age
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'games_played'}
                                                        direction={orderBy === 'games_played' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('games_played')}
                                                    >
                                                        GP
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'min_played'}
                                                        direction={orderBy === 'min_played' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('min_played')}
                                                    >
                                                        MIN
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'usage'}
                                                        direction={orderBy === 'usage' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('usage')}
                                                    >
                                                        USG%
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'true_shooting'}
                                                        direction={orderBy === 'true_shooting' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('true_shooting')}
                                                    >
                                                        TS%
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'o_gpm'}
                                                        direction={orderBy === 'o_gpm' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('o_gpm')}
                                                    >
                                                        O-GPM
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'd_gpm'}
                                                        direction={orderBy === 'd_gpm' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('d_gpm')}
                                                    >
                                                        D-GPM
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'gpm'}
                                                        direction={orderBy === 'gpm' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('gpm')}
                                                    >
                                                        GPM
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'war'}
                                                        direction={orderBy === 'war' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('war')}
                                                    >
                                                        gWAR
                                                    </TableSortLabel>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedAndFilteredData.map((player, index) => (
                                                <TableRow
                                                    key={`${player.player_id}_${player.team_id}`}
                                                    sx={{
                                                        '& > *': { py: '2px' },
                                                        '&:hover': {
                                                            '& .MuiTableCell-root:not(.player-name-cell)': {
                                                                filter: 'brightness(90%)',
                                                            },
                                                            '& .player-name-cell': {
                                                                backgroundColor: 'rgb(240, 238, 245)', // Slightly darker than the original
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>{index + 1}</TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        className="sticky left-0 z-10 player-name-cell"
                                                        sx={{
                                                            ...commonCellStyle,
                                                            backgroundColor: 'rgb(250, 248, 255)',
                                                            position: 'sticky',
                                                            left: 0,
                                                            zIndex: 10,
                                                        }}
                                                    >
                                                        <Link
                                                            to={`/player-profile/${player.player_id}`}
                                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                                            className="player-link"
                                                        >
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    color: 'black',
                                                                    fontSize: '0.875rem',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    maxWidth: '100%',
                                                                    textDecoration: 'underline',
                                                                    '&:hover': {
                                                                        color: '#8685EF'
                                                                    }
                                                                }}
                                                                style={{ ...responsiveStyle, ...responsiveRowStyle }}
                                                            >
                                                                {showJapanese ? player.player_name : player.player_name_eng}
                                                            </Typography>
                                                        </Link>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                color: 'grey',
                                                                fontSize: '0.75rem',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                maxWidth: '100%'
                                                            }}
                                                            style={{ ...responsiveStyle, ...responsiveRowStyle }}
                                                        >
                                                            {showJapanese ? player.team_names.join(', ') : player.team_names_eng.join(', ')} - {player.position}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        className="left-column"
                                                        sx={{
                                                            ...commonCellStyle,
                                                            textAlign: 'center'
                                                        }}
                                                        style={responsiveStyle}
                                                    >
                                                        {player.age}
                                                    </TableCell>
                                                    <TableCell
                                                        className="left-column"
                                                        sx={{
                                                            ...commonCellStyle,
                                                            textAlign: 'center'
                                                        }}
                                                        style={responsiveStyle}
                                                    >
                                                        {player.games_played}
                                                    </TableCell>
                                                    <TableCell
                                                        className="left-column"
                                                        sx={{
                                                            ...commonCellStyle,
                                                            textAlign: 'center'
                                                        }}
                                                        style={responsiveStyle}
                                                    >
                                                        {player.min_played.toFixed(1)}
                                                    </TableCell>
                                                    <ColoredCell
                                                        value={player.usage}
                                                        percentile={percentiles['usage']?.[player.usage]}
                                                        format={value => `${(value * 100).toFixed(2)}%`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.true_shooting}
                                                        percentile={percentiles['true_shooting']?.[player.true_shooting]}
                                                        format={value => `${(value * 100).toFixed(2)}%`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.o_gpm}
                                                        percentile={percentiles['o_gpm']?.[player.o_gpm]}
                                                        format={value => value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2)}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.d_gpm}
                                                        percentile={percentiles['d_gpm']?.[player.d_gpm]}
                                                        format={value => value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2)}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.gpm}
                                                        percentile={percentiles['gpm']?.[player.gpm]}
                                                        format={value => value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2)}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.war}
                                                        percentile={percentiles['war']?.[player.war]}
                                                        format={value => value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2)}
                                                        sx={commonCellStyle}
                                                    />
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </TableContainer>
                </div>
            </Container>
        </PageTransition>
    );
};

export default GPMTable
